import { useEffect, useState } from "react";
import './Assistencia.css'
import LlistaAssistencies, { applyTimeZone } from "./LlistaAssistencies";
import { fetchAPI, postAPI } from "../../../utils/utils";
import AssistanceChart from "./AssistanceChart";
import AssistenciesGraph from "./AssistenciesGraph";
import AssistenciesLog from "./AssistenciesLog";

function EmailAccessManager({ socket, mailsToShare, setMailsToShare }) {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    mail: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0.5rem',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    deleteBtn: {
      backgroundColor: 'red',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      padding: '0.25rem 0.5rem',
      cursor: 'pointer',
    },
    addBtn: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      padding: '0.25rem 0.5rem',
      cursor: 'pointer',
    },
    label: {
      marginRight: '1rem',
      fontSize: '0.85rem',
    }
  };

  const isTextAMail = text => {
    const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return mailRegex.test(text);
  };

  return (
    <div>
      <h4>Correus amb accés als Google Sheets d'assistència</h4>
      <div style={styles.container}>
        {mailsToShare.map(mail => (
          <div key={mail} style={styles.mail}>
            <div style={styles.label}>
              {mail}
            </div>
            <button
              style={styles.deleteBtn}
              onClick={() => {
                socket.emit('.remove_default_mail', mail);
              }}
            >
              Elimina
            </button>
          </div>
        ))}

        <button
          style={styles.addBtn}
          onClick={() => {
            const newMail = prompt('Introdueix el correu electrònic de la persona a afegir');
            if (newMail && isTextAMail(newMail)) {
              socket.emit('.add_default_mail', newMail);
            } else if (newMail) {
              alert('El correu introduït no és un correu vàlid');
            }
          }}
        >
          + Afegeix un correu
        </button>
      </div>
    </div>
  );
}

function ResumAssistencia(props) {
    const { event, assistencies, socket, castellersInfo, noClick, extended, current, past } = props;
    const [assistenciesEvent, setAssistenciesEvent] = useState([]);
    const [popupClosed, setPopupClosed] = useState(true);
    const [waitingResponse, setWaitingResponse] = useState(false);
    const [mailsToShare, setMailsToShare] = useState([]);
    const [showAssistenciesLog, setShowAssistenciesLog] = useState(false);
    const [showEmailManager, setShowEmailManager] = useState(false);

    const getName = r => r.mote || r.nom[0] + ' ' + r.cognom;

    useEffect(() => {
        if (assistencies !== undefined) return;
        
        fetchAPI(`/assistencies_event/${props.event}`, data => setAssistenciesEvent(data.data))

        socket.on(`.confirmat`, res => {
            if (res.event === props.event) {
                fetchAPI(`/assistencies_event/${props.event}`, data => setAssistenciesEvent(data.data))
            }
        })

        return () => {
            socket.off('.assistencies_event');
            socket.off('.confirmat');
        }
    }, [castellersInfo]);

    useEffect(() => {
        socket.emit('.get_default_mails')
        socket.on('.default_mails', data => setMailsToShare(data['writers']))
        return () => socket.off('.default_mails')
    }, [])

    const castellers = (assistencies || assistenciesEvent)
        .filter(c => parseInt(c?.canalla) !== 1)
        .filter(c => parseInt(c?.casteller) === 1)

    const fitxats = castellers.filter(row => row.assistencia === 'Fitxat');
    const venen = castellers.filter(row => row.assistencia === 'Vinc');
    const no_venen = castellers.filter(row => row.assistencia === 'No vinc');
    const no_confirmats = castellers
        .filter(row => row.assistencia === 'No confirmat')
        // Que no surtin novatos
        .filter(row => !row?.mote || !row.mote.includes('#'))

    const canalla = (assistencies || assistenciesEvent).filter(c => parseInt(c.canalla) === 1)
    const musics = (assistencies || assistenciesEvent).filter(c => parseInt(c?.music) === 1)
    const novells = (assistencies || assistenciesEvent).filter(c => c?.mote?.includes('#'))

    const eventInfo = {
        title: props.title,
        data_inici: props['data-inici'],
        data_fi: props['data-fi'],
    }

    const assistenciesHour = castellers
        .map(c => ({
            'id': c.id,
            'name': c.mote || `${c.nom} ${c.cognom}`,
            'assist': c['assistència'],
            'entrada': !c['data-entrada'] || isNaN(Date.parse(c['data-entrada'])) ? applyTimeZone(eventInfo.data_inici).toDate() : applyTimeZone(c['data-entrada']).toDate(),
            'sortida': !c['data-sortida'] || isNaN(Date.parse(c['data-sortida'])) ? applyTimeZone(eventInfo.data_fi).toDate() : applyTimeZone(c['data-sortida']).toDate(),
        }))

    return (
        <>
            { !noClick && <LlistaAssistencies assistencies={assistencies || assistenciesEvent} popupClosed={popupClosed} setPopupClosed={setPopupClosed} {...props} /> }
            
            { noClick && (
                <>
                    { showAssistenciesLog && <AssistenciesGraph eventId={event} data_inici={eventInfo.data_inici} data_fi={eventInfo.data_fi} /> }
                    { showAssistenciesLog && <AssistenciesLog eventId={event} castellersInfo={castellersInfo} data_inici={eventInfo.data_inici} data_fi={eventInfo.data_fi} />}
                </>
            )}
            
            <div className="assistencia-summary" onClick={() => !noClick && setPopupClosed(false)}>
                { fitxats.length > 0 && <div className="fitxats" title={fitxats.map(getName).join('\n')} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span>📍</span>
                    <span>{fitxats.length}</span>
                </div> }
                <div className="venen" title={venen.map(getName).join('\n')} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span>
                        { 
                            current ? <>🏃‍♀️ </> :
                            past ? <>👻 </> :
                            <>&#9989; </>
                        }
                    </span>
                    <span>
                        {venen.length}
                    </span>
                </div>
                <div className="no_venen" title={no_venen.map(getName).join('\n')} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span>
                        &#10060;
                    </span>
                    <span>
                        {no_venen.length}
                    </span>
                </div>
                <div className="no_confirmats" title={no_confirmats.map(getName).join('\n')} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <span>
                        😶
                    </span>
                    <span>
                        {no_confirmats.length}
                    </span>
                </div>
            </div>
            { extended && <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10 }}>
                    <button
                        onClick={() => {
                            setWaitingResponse(true);

                            postAPI('/export_assistance', {
                                selectedEvent: event,
                                eventInfo,
                                assistanceEvent: assistencies || assistenciesEvent,
                                castellersInfo,
                                options: {
                                    assistanceTypesToExport: ['Vinc', 'Fitxat', 'No vinc', 'No confirmat'],
                                    extraColumnsToExport: []
                                },
                                mailsToShare
                            }, data => {
                                setWaitingResponse(false);
                                window.open(data.url, '_blank')
                            })
                        }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px 20px',
                            backgroundColor: 'lightgreen',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            color: 'black',
                            fontSize: '14px',
                        }}
                    >
                        {
                            waitingResponse ? <span>Creant Google Sheet...</span> :
                            <span>Exporta l'assistència a Google Sheets</span>
                        }
                    </button>
                    
                    <div style={{ display: 'flex', gap: 10 }}>
                        <button 
                            onClick={() => setShowEmailManager(!showEmailManager)}
                            style={{
                                backgroundColor: '#eee',
                                border: 'none',
                                padding: '8px 16px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                color: 'black',
                                flex: 1,
                                justifyContent: 'center'
                            }}
                        >
                            <span style={{ transform: showEmailManager ? 'rotate(90deg)' : 'none', transition: 'transform 0.2s' }}>
                                ▶
                            </span>
                            Gestiona l'accés als Google Sheets
                        </button>

                        <button 
                            onClick={() => setShowAssistenciesLog(!showAssistenciesLog)} 
                            style={{ 
                                padding: '8px 16px', 
                                backgroundColor: '#007bff', 
                                color: '#fff', 
                                border: 'none', 
                                borderRadius: '5px', 
                                cursor: 'pointer',
                                flex: 1
                            }}
                        >
                            {showAssistenciesLog ? 'Amaga Historial' : 'Mostra Historial'}
                        </button>
                    </div>
                </div>

                {showEmailManager && (
                    <EmailAccessManager 
                        socket={socket} 
                        mailsToShare={mailsToShare} 
                        setMailsToShare={setMailsToShare} 
                    />
                )}
            </> }
            { !props.noInfo &&
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                        marginTop: 10,
                    }}
                >
                    <AssistanceChart
                        assistencies={assistenciesHour}
                        eventInfo={eventInfo}
                        castellersInfo={castellersInfo}
                        socket={socket}
                    />
                </div>
            }
        </>
    )
}

export default ResumAssistencia;