import moment from 'moment';
import { useEffect, useState } from 'react';
import styles from './Prova.styles'

import { rebuildPosicions } from '../../../utils/loadPositions';
import { fetchAPI, postAPI } from '../../../utils/utils';
import { applyTimeZone } from '../assistencia/LlistaAssistencies';

function Prova(props) {
    const { allPosicions, setAllPosicions, bundleInfo, order, setOrder, setCaixesCastellers, castellersInfo, userInfo, socket, prova, setProves, event, isPrivate, isAdmin, duplicate, hores, absoluteHores, assistencies } = props;
    const castellName = bundleInfo?.nom
    const castellShortName = bundleInfo?.shortName
    const castellDisplayName = castellShortName || castellName

    const isCapDeTecnica = userInfo?.es_tecnica >= 2;

    const castellId = prova.split('.').length > 0 ? prova.split('.')[0] : '';
    const versioName = prova.split('.').length > 1 ? prova.split('.')[1] : '';

    const change_state_prova = () => isCapDeTecnica ? socket.emit('.change_state_prova', event, castellId, versioName, isCapDeTecnica) : null
    const confirm_delete_prova = () => {
        if (!isCapDeTecnica) return;

        if (window.confirm(`Segur que vols eliminar el ${castellName}?`)) {
            postAPI('/delete_prova', { event, castell: castellId, versio: versioName }, res => {
                if (res.success) {
                    setProves(prev => ({
                        ...prev,
                        public: prev.public.filter(p => p !== `${castellId}.${versioName}.canvis`),
                        private: prev.private.filter(p => p !== `${castellId}.${versioName}.canvis`),
                        admin: prev.admin.filter(p => p !== `${castellId}.${versioName}.canvis`),
                    }))

                    // Also change order
                    socket.emit('.change_order', event, order.filter(prova => prova !== `${castellId}.${versioName}.canvis`));
                    setOrder(order.filter(prova => prova !== `${castellId}.${versioName}.canvis`));
                } else {
                    alert(`Error eliminant la prova! Envia-li al Mates una captura de pantalla d'aquest missatge: ${res?.error} ${JSON.stringify(res?.details)}`)
                }
            });
        }
    };

    const toMoment = dateStr => applyTimeZone(dateStr)
    const getName = assist => assist.mote || (assist.nom + ' ' + assist.cognom)

    const arribenTard = assistencies
        .filter(assist => parseInt(assist['assistència']) === 1)
        .filter(assist => !isNaN(Date.parse(assist['data-entrada'])))
        .filter(assist => moment(absoluteHores[0]) < toMoment(assist['data-entrada']))
        .map(assist => [assist.id, getName(assist)])

    const surtenAviat = assistencies
        .filter(assist => parseInt(assist['assistència']) === 1 || parseInt(assist['assistència']) === 2)
        .filter(assist => !isNaN(Date.parse(assist['data-sortida'])))
        .filter(assist => toMoment(assist['data-sortida']) < moment(absoluteHores[1]))
        .map(assist => [assist.id, getName(assist)])

    const noVenen = assistencies
        .filter(assist => parseInt(assist['assistència']) === 0)
        .map(assist => [assist.id, getName(assist)])

    const [posicionsLog, setPosicionsLog] = useState(null);
    const [posicions, setPosicions] = useState({});
    const [troncJSON, setTroncJSON] = useState(null);

    useEffect(() => {
        socket.emit('.load_bundle', castellId)

        socket.on('.loaded_json', (json, id) => {
            if (id === castellId) {
                setTroncJSON(
                    Object.fromEntries(
                        Object.entries(json || {})
                            .filter(([id, caixa]) => caixa?.pestanya?.toLowerCase() === 'tronc' || caixa?.pilar !== undefined)
                    )
                )
            }
        })

        return () => {
            socket.off('.loaded_json')
        }
    }, [castellId])

    useEffect(() => {
        if (!bundleInfo?.simultani) return;

        fetchAPI(`/events/${event}/renglesambfustesicolumnesambsim/${castellId}.${versioName}`, data => {
            const caixes_castellers = data
                .flat()
                .filter(([caixa, casteller]) => !['nom', 'fusta'].includes(caixa))
                .filter(([caixa, casteller]) => casteller in castellersInfo)
                .map(([caixa, casteller]) => [
                    {
                        pestanya: 'tronc',
                        // Other info from caixa
                    },
                    castellersInfo[casteller]
                ])
                
            setCaixesCastellers(prev => ({
                ...prev,
                [prova]: {
                    prova: bundleInfo,
                    caixesCastellers: caixes_castellers  
                }
            }))
        })
    }, [castellersInfo, prova, event, castellId, versioName, bundleInfo?.simultani])

    useEffect(() => {
        if (!posicions || !prova) return;
        setAllPosicions(prev => ({
            ...prev,
            [prova]: posicions
        }));
    }, [posicions, prova]);

    useEffect(() => {
        if (!event || !castellId || !versioName) return;

        const updatePosicions = res => {
            if ('error' in res) {
                console.log(res.error)
            } else if (res.data.split('\n').length > 0) {
                // setAjuntament(parametrizeOption('ajuntament', res.data.split('\n')[0]))
                setPosicionsLog(res.data.split('\n').slice(1))
            }
        }

        fetchAPI(`/positions/${event}/${castellId}/${versioName}`, updatePosicions)
        socket.on('.undid_action', action_id => fetchAPI(`/positions/${event}/${castellId}/${versioName}`, updatePosicions))

        socket.on('.posicions_changed', (ev, castell, versio, pos) => {
            if (versio === versioName && castell === castellId && parseInt(ev) === parseInt(event)) {
                setPosicionsLog(prevLog => prevLog ? [...prevLog, pos] : [pos])
            }
        });

        return () => {
            socket.off('.posicions_changed');
            socket.off('.undid_action');
        }
    }, [event, castellId, versioName]);

    useEffect(() => {
        if (posicionsLog === null) return;
        setPosicions(rebuildPosicions({ 'posicionsLog': posicionsLog }))
    }, [posicionsLog]);

    const castellersInProva = Object.keys(posicions.castellers || {})
        .map(id => parseInt(id))

    const castellersWithRetards = [...arribenTard, ...surtenAviat]
        .filter(([id, _]) => castellersInProva.includes(id))
        .map(([id, name]) => name)

    const castellersNoVenen = noVenen
        .filter(([id, _]) => castellersInProva.includes(id))
        .map(([id, name]) => name)

    const caixaCastellerTronc = Object.keys(troncJSON || {})
        .map(caixa_id => [caixa_id, posicions?.caixes?.[caixa_id]])
        .filter(([caixa, casteller]) => casteller)
        .filter(([caixa, casteller]) => casteller in castellersInfo)
        .map(([caixa, casteller]) => [
            troncJSON[caixa],
            castellersInfo[casteller]
        ])

    useEffect(() => {
        if (bundleInfo?.simultani) return;

        setCaixesCastellers(prev => ({
            ...prev,
            [prova]: {
                prova: bundleInfo,
                caixesCastellers: caixaCastellerTronc  
            }
        }))
    }, [
        JSON.stringify(caixaCastellerTronc),
        JSON.stringify(bundleInfo),
        prova,
    ])

    const isProvaEmpty = posicionsLog && (posicionsLog?.length < 2 || Object.values(rebuildPosicions({ posicionsLog }).castellers).length === 0)

    return (
        <>
            <div style={{ flex: 8 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                        <a style={{ fontSize: 12, color: "#2859A8" }} href={`/${event}/${castellId}/${versioName}/edit`}>
                            {castellDisplayName}
                        </a>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            { isProvaEmpty && <span
                                style={{
                                    backgroundColor: 'rgb(94 144 224)',
                                    color: 'white',
                                    borderRadius: 5,
                                    padding: 5,
                                    fontSize: 10,
                                }}
                            >
                                Buida
                            </span> }
                        </div>
                    </div>
                    { [...castellersWithRetards, ...castellersNoVenen].length > 0 &&
                        <div style={{ flex: 2, overflow: 'scroll', height: 50 }}>
                            <div style={{ display: 'flex', height: '100%', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    castellersWithRetards.map(retard => {
                                        return (<div key={retard} style={{ color: 'white', backgroundColor: 'rgb(255,50,50)', borderRadius: 5, margin: 5, fontSize: 9, padding: 3 }}>
                                            🕣 {retard}
                                        </div>)
                                    })
                                }
                                {
                                    castellersNoVenen.map(no_ve => {
                                        return (<div key={no_ve} style={{ color: 'white', backgroundColor: 'rgb(255,50,50)', borderRadius: 5, margin: 5, fontSize: 9, padding: 3 }}>
                                            {no_ve}
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    }
                    <div style={{ flex: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                        <div className="obrir-blank" style={{ display: 'flex', alignItems: 'center' }}>
                            <a style={{ textDecorationLine: 'none' }} target="_blank" href={`/${event}/${castellId}/${versioName}/edit`}>↗️ </a>
                        </div>
                        <div>
                            <button
                                style={{ backgroundColor: '#eee', border: 'solid 1px black' }}
                                onClick={change_state_prova}
                            >
                                { isAdmin ? '🛡️' : isPrivate ? '🔒' : '🌐' }
                            </button>
                        </div>
                        {
                            isCapDeTecnica && (
                                <div className='eliminar-prova-boto'>
                                    <button
                                        style={{ backgroundColor: '#eee', border: 'solid 1px black' }}
                                        onClick={confirm_delete_prova}
                                    >
                                        &#128465;
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* <div style={{ fontSize: 12 }}>
                    {
                        caixaCastellerTronc
                            .map(([caixa, casteller]) => `${casteller.mote} (${caixa.pilar})`)
                            .join(', ')
                    }
                </div> */}
            </div>
        </>
    );
}

export default Prova;