import { useEffect } from "react";
import { fetchAPI } from "../../utils/utils";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import 'chartjs-plugin-datalabels';
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ChartDataLabels);

const string2color = (str = '', transparency = 1, seed = 1) => {
    const safeStr = str || 'default';
    
    let hash = seed;
    safeStr.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });

    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        const blend = Math.round(value * transparency + (255 * (1 - transparency)));
        colour += blend.toString(16).padStart(2, '0');
    }
    return colour;
}

function ColorLegend({ tipus }) {
    return (
        <div
            style={{
                borderRadius: 5,
                backgroundColor: string2color(tipus),
                width: 25,
                height: 15,
            }}
        >
            &nbsp;
        </div>
    )
}

function IncomeSeries({ 
    passatFirst, 
    filteredEvents, 
    filtersEvents, 
    setFiltersEvents, 
    startDate,
    availableYears,
    COLLA,
    selectedYear,
    setSelectedYear
}) {
    const [totalMoney, setTotalMoney] = useState({})
    const [hiddenEvents, setHiddenEvents] = useState([])

    useEffect(() => {
        filteredEvents.forEach(ev => {
            if (!ev.id) return;

            fetchAPI(`/get_form_total_money/${ev.id}`, (data) => {
                setTotalMoney(prev => ({
                    ...prev,
                    [ev.id]: data.total_money
                }))
            })
        })
    }, [
        filteredEvents.length
    ])

    const eventsWithMoney = filteredEvents
        .filter(ev => totalMoney?.[ev.id] > 0)
        .filter(ev => !hiddenEvents.includes(ev.id))
        .sort((a, b) => moment(a['data-esperada-inici']).diff(moment(b['data-esperada-inici'])));

    const counts = eventsWithMoney
        .map(ev => totalMoney?.[ev.id] || 0)

    const labels = eventsWithMoney
        .map(ev => ev.title)

    const colors = eventsWithMoney
        .map(ev => [
            moment() < moment(ev['data-esperada-fi']), 
            ev.tipus || 'default'
        ])
        .map(([past, tipus]) => 
            string2color(tipus, ((passatFirst && past) || (!passatFirst && !past)) ? 0.5 : 1)
        );

    const totalMoneyRaised = counts.reduce((acc, count) => acc + count, 0);

    const minCount = Math.max(0, Math.min(...counts) - 2);
    const maxCount = Math.max(...counts) + 2;

    const data = {
        labels: labels,
        datasets: [{
            label: "Ingressos (€)",
            data: counts,
            backgroundColor: colors,
            datalabels: {
                anchor: 'end',
                align: 'top',
            }
        }],
    };

    const lengthYears = moment().diff(moment('01/01/2022', 'DD/MM/YYYY'), 'years')

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                color: '#666',
                font: {
                    weight: 'bold',
                    size: 10
                },
                formatter: (value) => `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€`,
                offset: 5
            }
        },
        scales: {
            y: {
                min: 0,
                max: Math.ceil((maxCount + 100) / 500) * 500,
            },
            x: {
                ticks: {
                    callback: function(index) {
                        const label = labels?.[index] ?? '';
                        // Limit label length and add ellipsis if too long
                        return label.length > 10 ? label.substring(0, 10) + '...' : label;
                    },
                    maxRotation: 45,
                    minRotation: 45
                },
            },
        },
        responsive: true,
        animation: {
            duration: 0,
        },
        maintainAspectRatio: false,
        onClick: function(event, elements, chart) {
            if (elements.length > 0) {
                const firstElement = elements[0];
                const index = firstElement.index;
                const clickedEvent = eventsWithMoney[index];
                setHiddenEvents(prev => [...prev, clickedEvent.id]);
            }
        }
    };
    
    return (<>
        <div style={{ marginBottom: 20 }}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                marginBottom: 12,
                height: '30px' // Fixed height to ensure vertical centering
            }}>
                <h3 style={{ 
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>Ingressos per activitat</h3>
                <div style={{ 
                    color: '#666',
                    fontSize: '16px',
                    display: 'flex', 
                    alignItems: 'center',
                    height: '100%'
                }}>
                    Total: <strong style={{ marginLeft: 5 }}>{totalMoneyRaised.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}€</strong>
                </div>
            </div>
            
            <select 
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                style={{ 
                    width: '100%',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                    backgroundColor: '#f8f8f8',
                    fontSize: '14px',
                    color: '#444',
                    cursor: 'pointer',
                    outline: 'none',
                    marginBottom: 15
                }}
            >
                {availableYears.map(year => (
                    <option key={year} value={year}>
                        {COLLA === 'sarri' 
                            ? `${year}` 
                            : `Setembre ${year} - Agost ${year + 1}`}
                    </option>
                ))}
            </select>
        </div>
        {
          eventsWithMoney.length === 0 ? (
            <div style={{ fontSize: '0.8rem', fontWeight: '300', color: '#555', marginBottom: 50 }}>
              No hi ha formularis en aquest rang de dates.
            </div>
          ) : (
            <div style={{ width: '100%', height: window.innerWidth > 768 ? 300 : 200 }}>
                <Bar data={data} options={options} />
            </div>
          )
        }
        {hiddenEvents.length > 0 && (
            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                    {hiddenEvents.map(eventId => {
                        const event = filteredEvents.find(e => e.id === eventId);
                        return (
                            <button
                                key={eventId}
                                onClick={() => setHiddenEvents(prev => prev.filter(id => id !== eventId))}
                                style={{
                                    padding: '5px 10px',
                                    borderRadius: '4px',
                                    border: '1px solid #ddd',
                                    backgroundColor: '#f8f8f8',
                                    cursor: 'pointer',
                                    color: '#666',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5
                                }}
                            >
                                <strong>{totalMoney?.[eventId] || 0}€</strong>
                                {event?.title || 'Unknown'} ✕
                            </button>
                        );
                    })}
                </div>
            </div>
        )}
    </>);
}

export default IncomeSeries;
