import { useState } from 'react';
import styles from './EventsCalendar.styles';

const MONTHS = [
    'Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny',
    'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'
];

const DAYS = ['Dl', 'Dt', 'Dc', 'Dj', 'Dv', 'Ds', 'Dg'];

function EventsCalendar({ events, onSelectDate, selectedDate }) {
    const [currentDate, setCurrentDate] = useState(new Date());

    const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
    
    const getFirstDayOfMonth = (year, month) => {
        let day = new Date(year, month, 1).getDay();
        return day === 0 ? 6 : day - 1; // Convert to Monday-based week
    };

    const getEventsForDate = (date) => {
        return events.filter(event => {
            const eventDate = new Date(event["data-esperada-inici"]);
            return eventDate.getDate() === date &&
                   eventDate.getMonth() === currentDate.getMonth() &&
                   eventDate.getFullYear() === currentDate.getFullYear();
        });
    };

    const renderCalendar = () => {
        const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDay = getFirstDayOfMonth(currentDate.getFullYear(), currentDate.getMonth());
        const days = [];

        // Add empty cells for days before the first day of the month
        for (let i = 0; i < firstDay; i++) {
            days.push(<div key={`empty-${i}`} style={styles.emptyDay}></div>);
        }

        // Add cells for each day of the month
        for (let day = 1; day <= daysInMonth; day++) {
            const eventsOnDay = getEventsForDate(day);
            const isSelected = selectedDate && 
                             selectedDate.getDate() === day && 
                             selectedDate.getMonth() === currentDate.getMonth() &&
                             selectedDate.getFullYear() === currentDate.getFullYear();

            days.push(
                <div
                    key={day}
                    onClick={() => onSelectDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day))}
                    style={{
                        ...styles.day,
                        ...(isSelected ? styles.selectedDay : {}),
                        ...(eventsOnDay.length > 0 ? styles.hasEvents : {})
                    }}
                >
                    <span>{day}</span>
                    {eventsOnDay.length > 0 && (
                        <div style={styles.eventDots}>
                            {[...Array(Math.min(eventsOnDay.length, 3))].map((_, i) => (
                                <div key={i} style={styles.eventDot} />
                            ))}
                            {eventsOnDay.length > 3 && (
                                <span style={styles.eventPlus}>+</span>
                            )}
                        </div>
                    )}
                </div>
            );
        }

        return days;
    };

    const changeMonth = (increment) => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + increment, 1));
    };

    return (
        <div style={styles.calendar}>
            <div style={styles.header}>
                <button onClick={() => changeMonth(-1)} style={styles.monthButton}>←</button>
                <span style={styles.monthYear}>
                    {MONTHS[currentDate.getMonth()]} {currentDate.getFullYear()}
                </span>
                <button onClick={() => changeMonth(1)} style={styles.monthButton}>→</button>
            </div>
            <div style={styles.daysHeader}>
                {DAYS.map(day => (
                    <div key={day} style={styles.dayName}>{day}</div>
                ))}
            </div>
            <div style={styles.daysGrid}>
                {renderCalendar()}
            </div>
        </div>
    );
}

export default EventsCalendar; 