import { useEffect, useState } from "react";
import Event from './Event';
import styles from './Events.styles'
import { fetchAPI } from "../../utils/utils";
import Pressable from "../other/Pressable";
import { useRef } from "react";
import EventsCalendar from './calendar/EventsCalendar';

function Separator() {
    return (
        <hr />
    )
}

function LlistaEvents(props) {
    const { socket, past, current, events, setEvents } = props;
    const event_type = props.type;

    const loadNMultiplier = 5;
    const [numOfFutureEvents, setNumOfFutureEvents] = useState(loadNMultiplier)

    const [ignoredEvents, setIgnoredEvents] = useState({})

    const [scrollToCurrent, setScrollToCurrent] = useState(false)
    const [showDownArrow, setShowDownArrow] = useState(false)
    const [showUpArrow, setShowUpArrow] = useState(false)
    const [beginScroll, setBeginScroll] = useState(false)

    const scrollableContainerRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(() => {
        // Initialize selectedDate from URL on component mount
        const path = window.location.pathname;
        const match = path.match(/\/dia\/(\d{4}-\d{2}-\d{2})/);
        if (match) {
            const dateStr = match[1];
            const date = new Date(dateStr);
            if (!isNaN(date.getTime())) {
                return date;
            }
        }
        return null;
    });

    const [showCalendar, setShowCalendar] = useState(false);

    // Update URL when date changes
    useEffect(() => {
        if (selectedDate) {
            // Adjust for timezone offset to ensure correct date
            const dateStr = selectedDate.getFullYear() + '-' + 
                String(selectedDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(selectedDate.getDate()).padStart(2, '0');
            window.history.replaceState({}, '', `/dia/${dateStr}`);
        } else {
            window.history.replaceState({}, '', '/');
        }
    }, [selectedDate]);

    const checkPosition = () => {
        if (!beginScroll) return;

        if (scrollableContainerRef.current) {
            const firstCurrentOrFutureEvent = 
                currentEvents.length > 0 ? currentEvents?.[0]?.id :
                futureEvents.length > 0 ? futureEvents?.[0]?.id :
                null;

            if (firstCurrentOrFutureEvent) {
                const element = document.getElementById(`event-${firstCurrentOrFutureEvent}`);
                if (element) {
                    const elementRect = element.getBoundingClientRect();
                    const containerRect = scrollableContainerRef.current.getBoundingClientRect();

                    if (elementRect.top - containerRect.top >= 100) {
                        setShowDownArrow(true);
                    }

                    if (elementRect.top - containerRect.top < 100) {
                        setShowDownArrow(false);
                    }

                    if (elementRect.top - containerRect.top <= -500) {
                        setShowUpArrow(true);
                    }

                    if (elementRect.top - containerRect.top > -500) {
                        setShowUpArrow(false);
                    }
                }
            }
        }
    }

    const beforeNow = dateString => (new Date(new Date(dateString).toLocaleString("en-US", {timeZone: 'UTC'}))) <= (new Date());
    const afterNow = dateString => (new Date()) <= (new Date(new Date(dateString).toLocaleString("en-US", {timeZone: 'UTC'})));

    const filteredEvents = (events || [])
        .filter(event => !['assaig', 'actuació', 'activitat'].includes(event_type) ? true : event.tipus === event_type)
        .sort((a, b) => new Date(a["data-esperada-inici"]) > new Date(b["data-esperada-inici"]) ? 1 : -1)

    const currentEvents = filteredEvents.filter(event => beforeNow(event["data-esperada-inici"]) && afterNow(event["data-esperada-fi"]))
    const futureEvents = filteredEvents.filter(event => afterNow(event["data-esperada-inici"]));
    // const selectedEvents = props.past ? pastEvents : props.current ? currentEvents : futureEvents;

    const selectedEvents = selectedDate ? 
        filteredEvents.filter(event => {
            const eventDate = new Date(event["data-esperada-inici"]);
            return eventDate.getDate() === selectedDate.getDate() &&
                   eventDate.getMonth() === selectedDate.getMonth() &&
                   eventDate.getFullYear() === selectedDate.getFullYear();
        }) :
        [
            ...currentEvents,
            ...futureEvents.slice(0,numOfFutureEvents),
        ];

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTimeout(() => setBeginScroll(true), 5000)
    }, [])

    useEffect(() => {
        setIgnoredEvents({})
    }, [
        numOfFutureEvents,
    ])

    // useEffect(() => {
    //     const firstCurrentOrFutureEvent = 
    //         currentEvents.length > 0 ? currentEvents?.[0]?.id :
    //         futureEvents.length > 0 ? futureEvents?.[0]?.id :
    //         null;
    
    //     if (firstCurrentOrFutureEvent) {
    //         const element = document.getElementById(`event-${firstCurrentOrFutureEvent}`);
    //         if (element && scrollableContainerRef.current) {
    //             const scrollableContainer = scrollableContainerRef.current;
    //             const elementRect = element.getBoundingClientRect();
    //             const containerRect = scrollableContainer.getBoundingClientRect();
    
    //             // Scroll only if the element is not visible within the container
    //             if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
    //                 scrollableContainer.scrollTop += elementRect.top - containerRect.top - 10;
    //             }
    //         }
    //     }
    // }, [
    //     event_type,
    //     scrollToCurrent,
    //     currentEvents?.[0]?.id,
    //     futureEvents?.[0]?.id,
    // ]);

    // useEffect(() => {
    //     if (numOfPastEvents > loadNMultiplier) {
    //         const targetEvent = pastEvents?.[loadNMultiplier - 1]?.id;
    //         if (targetEvent) {
    //             const element = document.getElementById(`event-${targetEvent}`);
    //             if (element && scrollableContainerRef.current) {
    //                 const scrollableContainer = scrollableContainerRef.current;
    //                 const elementRect = element.getBoundingClientRect();
    //                 const containerRect = scrollableContainer.getBoundingClientRect();

    //                 // Scroll only if the element is not visible within the container
    //                 if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
    //                     scrollableContainer.scrollTop += elementRect.top - containerRect.top - 50;
    //                 }
    //             }
    //         }
    //     }
    // }, [
    //     event_type,
    //     numOfPastEvents,
    //     pastEvents?.[loadNMultiplier - 1]?.id
    // ]);

    const llista_events = selectedEvents
        .filter(event => !event?.targets?.split(',')?.includes('músics'))
        .filter(event => !ignoredEvents[event.id])
        .map(event => {
            return (
                <div id={`event-${event.id}`} style={styles.event_container} key={`${event.id}`}>
                    <Event
                        event_id={event.id}
                        title={event.title}
                        description={event.description}
                        event={event.id}
                        lloc={event.lloc}
                        targets={event.targets}
                        hash={event.hash}
                        data-inici={event["data-esperada-inici"]}
                        data-fi={event["data-esperada-fi"]}
                        past={beforeNow(event["data-esperada-fi"])}
                        ignoredEvents={ignoredEvents}
                        setIgnoredEvents={setIgnoredEvents}
                        {...props}
                    />
                </div>
            );
        });

    const PROXIMS_ES = ['actuacions', 'activitats'].includes(event_type) ? 'PRÒXIMES' : 'PRÒXIMS';

    const handleDateSelect = (date) => {
        if (date) {
            setSelectedDate(
                selectedDate && 
                date.getDate() === selectedDate.getDate() && 
                date.getMonth() === selectedDate.getMonth() &&
                date.getFullYear() === selectedDate.getFullYear() 
                ? null : date
            );
        } else {
            setSelectedDate(null);
            setShowCalendar(false);
        }
    };

    if (selectedEvents.length > 0) {
        return (
            <div style={{ position: 'relative' }}>
                <Pressable 
                    style={{
                        margin: '20px auto',
                        padding: '10px 20px',
                        backgroundColor: '#ddd',
                        color: '#333',
                        borderRadius: 10,
                        cursor: 'pointer',
                        maxWidth: 400,
                        width: '80%',
                        border: '1px solid #ccc',
                        fontSize: 14,
                        transition: 'all 0.2s ease',
                        ':hover': {
                            backgroundColor: '#333',
                            color: 'white'
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 15
                    }}
                    onClick={() => showCalendar ? handleDateSelect(null) : setShowCalendar(true)}
                >
                    {showCalendar ? (
                        <>
                            <span>⌚</span>
                            <span>Torna a avui</span>
                        </>
                    ) : (
                        <>
                            <span>📅</span>
                            <span>Ves a un altre dia</span>
                        </>
                    )}
                </Pressable>

                {showCalendar && (
                    <EventsCalendar 
                        events={filteredEvents}
                        onSelectDate={handleDateSelect}
                        selectedDate={selectedDate}
                    />
                )}

                <div ref={scrollableContainerRef} onScroll={() => checkPosition()} style={{...styles.events_container, ...{ display: props.hidden ? 'none' : 'block' }}}>
                    { llista_events }

                    {
                        (!props.past && !props.current) && isLoading ? <>
                            <div style={{ margin: 20 }}>&nbsp;</div>
                            <div style={{ display: 'flex', margin: 20, justifyContent: 'center' }}>
                                <em>
                                    Carregant més {event_type}...
                                </em>
                            </div>
                            <div style={{ margin: 20 }}>&nbsp;</div>
                        </> : !past && !current && numOfFutureEvents < futureEvents.length ? <>
                            <Pressable onClick={() => setNumOfFutureEvents(prev => prev + loadNMultiplier)} style={styles.load_more}>
                                <div style={styles.load_more_text}>+ Carrega'n més</div>
                            </Pressable>
                        </> : <>
                        </>
                    }
                </div>
            </div>
        );
    } else if (events === null && !past && !current) {
        return (
            <>
                <div style={{ display: 'flex', margin: 20, justifyContent: 'center' }}>
                    <em>
                        Carregant {event_type}...
                    </em>
                </div>
                <div style={{ margin: 50 }}>&nbsp;</div>
            </>
        )
    } else if (selectedEvents.length === 0 && !past && !current) {
        return (
            <>
                <Pressable 
                    style={{
                        margin: '20px auto',
                        padding: '10px 20px',
                        backgroundColor: '#ddd',
                        color: '#333',
                        borderRadius: 10,
                        cursor: 'pointer',
                        maxWidth: 400,
                        width: '80%',
                        border: '1px solid #ccc',
                        fontSize: 14,
                        transition: 'all 0.2s ease',
                        ':hover': {
                            backgroundColor: '#333',
                            color: 'white'
                        },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 15
                    }}
                    onClick={() => showCalendar ? handleDateSelect(null) : setShowCalendar(true)}
                >
                    {showCalendar ? (
                        <>
                            <span>📅</span>
                            <span>Torna a avui</span>
                        </>
                    ) : (
                        <>
                            <span>📅</span>
                            <span>Ves a un altre dia</span>
                        </>
                    )}
                </Pressable>

                {showCalendar && (
                    <EventsCalendar 
                        events={filteredEvents}
                        onSelectDate={handleDateSelect}
                        selectedDate={selectedDate}
                    />
                )}

                <div style={{ display: 'flex', margin: 20, justifyContent: 'center' }}>
                    <em>
                        No hi ha {PROXIMS_ES.toLowerCase()} {event_type}
                    </em>
                </div>
                <div style={{ margin: 10 }}>&nbsp;</div>
            </>
        )
    }
}

export default LlistaEvents;