import { useState, useEffect } from 'react';
import IncomeSeries from './IncomeSeries';
import moment from 'moment';
import { getSubdomain, fetchAPI } from '../../utils/utils';

const COLLA = getSubdomain();

function FormsStats({ forms }) {
  const [eventsInfo, setEventsInfo] = useState([]);
  const [filtersEvents, setFiltersEvents] = useState({
    assaigs: false,
    actuacions: false,
    activitats: false,
    ids: []
  });

  useEffect(() => {
    fetchAPI('/events', (data) => {
      setEventsInfo(data);
    });
  }, []);

  // Get all available years that have events with income
  const getAvailableYears = () => {
    return eventsInfo
      .map(event => moment(event['data-esperada-inici']).year())
      .filter(year => year <= moment().year())
      .filter(year => {
        const currentMonth = moment().month(); // 0-11 where 8 is September
        return year <= moment().year() && (currentMonth >= 8 || year < moment().year());
      })
      .filter((year, index, self) => self.indexOf(year) === index) // unique years
      .sort((a, b) => b - a); // sort descending
  };

  const availableYears = getAvailableYears();
  const [selectedYear, setSelectedYear] = useState(() => {
    const currentMonth = moment().month(); // 0-11 where 8 is September
    const currentYear = moment().year();
    return currentMonth >= 8 ? currentYear : currentYear - 1;
  });

  // Get start date based on selected year
  const getStartDate = () => {
    // For colla "sarri", use January of selected year
    if (COLLA === 'sarri') {
      return moment(`${selectedYear}-01-01`);
    }
    
    // For all other colles, use September of selected year
    return moment(`${selectedYear}-09-01`);
  };

  const getEndDate = () => {
    if (COLLA === 'sarri') {
      return moment(`${selectedYear}-12-31`);
    }
    return moment(`${selectedYear + 1}-08-31`);
  };

  const events = Object.keys(forms)
    .map(eventId => ({
      ...eventsInfo.find(ev => parseInt(ev.id) === parseInt(eventId)),
      id: eventId,
      title: forms[eventId].title,
      tipus: forms[eventId].tipus
    }))
    .filter(event => {
      // Filter out events in filtersEvents.ids
      if (filtersEvents.ids.includes(event.id)) return false;
      
      // Filter out events by type if filtered
      if (filtersEvents.assaigs && event.tipus === 'assaig') return false;
      if (filtersEvents.actuacions && event.tipus === 'actuació') return false;
      if (filtersEvents.activitats && event.tipus === 'activitat') return false;
      
      // Filter events based on date range
      const startDate = getStartDate();
      const endDate = getEndDate();
      const eventDate = moment(event['data-esperada-inici']);
      return eventDate.isSameOrAfter(startDate) && eventDate.isSameOrBefore(endDate);
    })
    .sort((a, b) => moment(a['data-esperada-inici']).diff(moment(b['data-esperada-inici'])));

  return (
    <div>
      <IncomeSeries
        passatFirst={false}
        filteredEvents={events}
        filtersEvents={filtersEvents}
        setFiltersEvents={setFiltersEvents}
        startDate={getStartDate()}
        availableYears={availableYears}
        COLLA={COLLA}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
    </div>
  );
}

export default FormsStats;
