const styles = {
    calendar: {
        width: '85%',
        maxWidth: 400,
        margin: '20px auto',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 15,
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    monthButton: {
        background: 'none',
        border: 'none',
        fontSize: 20,
        cursor: 'pointer',
        padding: '5px 10px',
        color: '#333',
    },
    monthYear: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    daysHeader: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 5,
        marginBottom: 10,
    },
    dayName: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#666',
        fontSize: 14,
    },
    daysGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 5,
    },
    day: {
        aspectRatio: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 5,
        position: 'relative',
        backgroundColor: '#f5f5f5',
        transition: 'background-color 0.2s',
        ':hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    emptyDay: {
        aspectRatio: '1',
    },
    selectedDay: {
        backgroundColor: '#333 !important',
        color: 'white',
    },
    hasEvents: {
        backgroundColor: '#e3f2fd',
    },
    eventCount: {
        position: 'absolute',
        bottom: 2,
        fontSize: 10,
        color: '#666',
    },
    eventDots: {
        display: 'flex',
        gap: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2px'
    },
    eventDot: {
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: '#007bff',
    },
    eventPlus: {
        fontSize: '8px',
        color: '#007bff',
        marginLeft: '1px',
        lineHeight: '1',
        display: 'flex',
        alignItems: 'center',
    },
};

export default styles; 